import { createGlobalStyle } from "styled-components"

/**
 * Styles that are applied to every page using the :root selector.
 */
const RootStyles = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500&display=swap');

  :root {
    
    --hmc-blue-gray: 12, 27, 43;
    --hmc-dark-blue: 20, 20, 20;
    --hmc-dark-gray: 5, 7, 9;
    --hmc-dark-brown: #050709;
    --hmc-heading: #2e3337;
    
    --bs-gray-200: #ededed;

    --hmc-shadow: 1, 1, 1;
    --hmc-gray: #D9D9D9;
    --hmc-green: #71BB4E;
    --hmc-dark-green: #6A8029;
    --hmc-light-gray: #ECF3DA;
    --hmc-light-green: #AECC53;
    --hmc-yellow: #EDD164;
    --hmc-black: #000;
    --hmc-white: #FFFFFC;
    --hmc-underline: #717171;
    --bs-white: var(--hmc-white);

    overflow-wrap: break-word;

    #content-container img {
      border-radius: 5px;
      max-width: 100%;
    }

    .leaflet-container {
      z-index: 9;
    }

    a {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
    }

    p, li {
      font-family: "Mulish", sans-serif;
      font-weight: 500;
      font-size: 18px;

      max-width: 700px;
    }

    a:link,
    a:visited {
      color: var(--hmc-heading);
      fill: var(--hmc-heading);
    }

    a:hover {
      color: var(--hmc-underline);
      fill: var(--hmc-underline);
      text-decoration: underline;
    }

    a:active {
      color: var(--hmc-dark-brown);
      fill: var(--hmc-dark-brown);
    }

    .btn-light {
      background-color: var(--hmc-yellow);
      border-color: var(--hmc-yellow);
    }

    .btn {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
    }

    #basic-navbar-nav > button:not(.selection-has-changed), 
    .offcanvas-body > button:not(.selection-has-changed) {
      display: none;
    }

    #basic-navbar-nav > button, 
    .offcanvas-body > button {
        height: 49px;
        padding: 0 20px;
    }
    
    h1,
    h2,
    h3,
    h4 {
      color: var(--hmc-heading);
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
    }

    h1 {
      font-size: 3.5rem;
      margin: .75rem auto 1.75rem;
      font-weight: 500;
      text-transform: uppercase;
      strong {
        font-weight: 900;
      }
    }

    h2 {
      font-size: 2.25rem;
      margin: 2rem auto .75rem;
    }

    h3 {
      font-size: 1.75rem;
      margin: 1.5rem auto .5rem;
    }

    .flash-green,
    .flash-green button,
    .flash-green div {
      animation: flash-green 0.5s;
    }

    @keyframes flash-green {
      50% {
        border-color: var(--hmc-green);
        background-color: var(--hmc-green);
      }
      100% {
        border-color: var(--hmc-yellow);
        background-color: var(--hmc-yellow);
      }
    }

    #homepage,
    #info-page,
    #hmc-online,
    #join-hmc,
    #department-page,
    #location-page {
      .hmc-background-image {
        margin-top: 112px;
      }
    }
    
    /*medium-large*/
    @media screen and (max-width: 992px) {

      @media screen and (max-height: 700px) {
        && #homepage .hmc-background-image {
          height: 28%;
        }
      }

      @media screen and (max-height: 600px) {
        && #homepage {
          #homepage-container {
            top: 50%;
          }
          .hmc-background-image {
            display: none;
          }
        }
      }

      #info-page,
      #hmc-online,
      #join-hmc,
      #department-page,
      #location-page {
        .hmc-background-image {
          display: none;
        }
      }

      #homepage .hmc-background-image {
        height: 35%;
        width: 70vw;
        min-width: 320px;
        aspect-ratio: auto;
        position: absolute;
        bottom: 112px;
        left: 50%;
        transform: translate(-50%, -0%);
        @media screen and (max-width: 576px) {
          bottom: 90px;
        }
      }

      h1 {
        font-size: 3rem;
        margin: 2rem auto;
      }

      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1.5rem;
      }
    }

    /*small*/
    @media screen and (max-width: 576px) {
      && h1 {
        font-size: 2.5rem;
        margin: 1.5rem auto;
      }

      #info-page,
      #hmc-online,
      #join-hmc {
        .hmc-footer {
          display: none;
        }
      }
    }
  }
`

export default RootStyles
